import {useUser} from "../user-auth/UserProvider";
import {UserRequiredRedirect} from "../user-auth/UserRequiredRedirect";
import * as React from "react";
import {
    Alert,
    Box,
    Breadcrumbs, Card, CardHeader, Chip, CircularProgress, Collapse,
    Divider, Drawer,
    FormControl,
    FormControlLabel, Grid, IconButton, InputAdornment,
    InputLabel, LinearProgress,
    List,
    ListItem, ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select, Stack, TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {NavLink, useParams} from "react-router-dom";
import Link from "@mui/material/Link";
import AppConsts from "../app/AppConsts";
import {PathGenerator, PathsMap} from "../app/PageRouting";
import {Page} from "../app/Page";
import {
    Timeline,
    TimelineConnector,
    TimelineContent, TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import Avatar from "@mui/material/Avatar";
import {
    red,
    pink,
    teal,
    green,
    lightBlue,
    lime,
    blue,
    indigo,
    deepOrange,
    deepPurple,
} from "@mui/material/colors";
import {SparkLineChart} from "@mui/x-charts";
import CountDownTimer from "../components/CountDownTimer";
import {useCallback, useEffect, useReducer, useRef, useState} from "react";
import {
    BoltOutlined,
    FavoriteBorderOutlined,
    PsychologyAltOutlined,
    BloodtypeOutlined,
    HeartBrokenOutlined,
    SmartButtonOutlined,
    HistoryOutlined,
    MedicalInformationOutlined,
    PersonPinCircleOutlined,
    MaleOutlined,
    FemaleOutlined,
    DialpadOutlined,
    AssignmentOutlined,
    HealthAndSafetyOutlined,
    KeyboardDoubleArrowLeftOutlined,
    InsightsOutlined,
    AutoAwesomeOutlined,
    PollOutlined,
    InfoOutlined, Done, PlayArrowOutlined, MoreVertOutlined
} from "@mui/icons-material";
import {
    DateTimePicker,
    renderTimeViewClock
} from "@mui/x-date-pickers";
import {TransitionGroup} from "react-transition-group";
import SwipeableEdgeDrawer from "../components/SwipeableEdgeDrawer";
import {httpClient} from "../utils/httpClient";
import {logError} from "../utils/logging";
import {debounce} from "../utils/debounce";
import {useSnackbar} from "../providers/SnackbarProvider";
import {HeadPaper, Theme} from "../app/Theme";
import {useErrors} from "../hooks/useErrors";
import {t} from "i18next";
import LeaveConfirm from "../components/LeaveConfirm";
import {FIELDS, STEP_STATES, STEPS} from "./AmbulanceModuleConfig";
import Button from "@mui/material/Button";

export function AmbulanceModulePageMiddleware() {
    const user = useUser();

    if (!user.logged) {
        return <UserRequiredRedirect/>;
    }
    return <ModulePage/>;
}

function moduleDataReducer(state, action = undefined) {
    switch (action.type) {
        case 'is_loading':
            return {loading: 'is_loading', dirty: 0, saving: false};
        case 'load_failed':
            return {loading: 'failed', dirty: 0, saving: false};
        case 'loaded_successfully':
            return {...action.data, loading: false, dirty: 0, saving: false};
        case 'is_saving_started':
            return {...state, saving: true};
        case 'is_saving_ended':
            return {...state, saving: false, steps: {...action.data.steps}};
        case 'is_saving_failed':
            return {...state, saving: false};
        case 'field_changed':
            const newState = {
                ...state,
                dirty: state.dirty + 1,
                [action.parent]: {...state[action.parent], [action.property]: action.value}
            };
            newState.steps = {};
            for (let stepsKey in STEPS) {
                newState.steps[stepsKey] = STEP_STATES.INCOMPLETE;

                if ('fields' in STEPS[stepsKey]) {
                    newState.steps[stepsKey] = STEPS[stepsKey].fields.every(field => field.isValid(newState))
                        ? STEP_STATES.COMPLETED : STEP_STATES.INCOMPLETE;
                }
            }
            return newState;
        default:
            throw new Error();
    }
}

function generateWarningsMessage(warnings) {
    if (warnings.length === 1) {
        return "Il campo evidenziato in rosso è obbligatorio";
    }
    return "I campi evidenziati in rosso sono obbligatori";
}

function buildPutRequestJson(data) {
    return {
        patient: {
            name: data.patient.name,
            genre: data.patient.genre,
            age: data.patient.age,
            fiscalCode: data.patient.fiscalCode
        },
        contact: {
            name: data.contact.name,
            type: data.contact.type,
            phoneNumber: data.contact.phoneNumber
        },
        anamnesis: {
            firstSymptomsAtKnown: data.anamnesis.firstSymptomsAtKnown,
            firstSymptomsAtType: data.anamnesis.firstSymptomsAtType,
            firstSymptomsAt: data.anamnesis.firstSymptomsAt,
            patientFoundAt: data.anamnesis.patientFoundAt,
            isPsychiatric: data.anamnesis.isPsychiatric,
            isEpileptic: data.anamnesis.isEpileptic,
            isHeartPatient: data.anamnesis.isHeartPatient
        },
        parameters: {
            minBloodPressure: data.parameters.minBloodPressure,
            maxBloodPressure: data.parameters.maxBloodPressure,
            oxygenSaturation: data.parameters.oxygenSaturation,
            glycemia: data.parameters.glycemia
        },
    };
}

function ModulePage() {
    const {createSnackbar} = useSnackbar();
    const {moduleId} = useParams();
    const [moduleData, dispatchModuleData] = useReducer(moduleDataReducer, {loading: 'is_loading'});
    const lastValidation = useRef(0);
    const lastSaveSent = useRef(0);
    const lastSaveDone = useRef(0);
    const [touchedFields, setTouchedFields] = useState([]);
    const [scales, setScales] = useState([]);
    const autosaveTimeout = useRef(null);
    const {errors, resetErrors, setErrors} = useErrors({
        mapper: ({details, message}) => {
            return {
                key: details?.field ?? "main",
                message: message
            };
        }
    });

    const validateForm = useCallback(() => {
        lastValidation.current = moduleData.dirty;
        resetErrors();
        const newErrors = [];
        for (let parentKey in FIELDS) {
            for (let fieldKey in FIELDS[parentKey]) {
                const fieldId = parentKey + "." + fieldKey;
                if (!touchedFields.includes(fieldId)) {
                    continue;
                }
                if (!FIELDS[parentKey][fieldKey].isValid(moduleData)) {
                    newErrors.push({
                        key: fieldId,
                        message: "Campo obbligatorio",
                        clientError: true
                    });
                }
            }
        }
        setErrors(newErrors);
    }, [moduleData, resetErrors, setErrors, touchedFields]);

    const handleFieldChange = useCallback(({parent, property, value, debounced}) => {
        const fieldId = parent + "." + property;

        const handle = () => {
            dispatchModuleData({
                parent: parent,
                property: property,
                value: value,
                type: 'field_changed'
            });
            setTouchedFields(fields => fields.includes(fieldId) ? [...fields] : [...fields, fieldId]);
        };

        if (debounced) {
            debounce(debounced, handle);
        } else {
            handle();
        }
    }, []);

    function markAllFieldsAsTouched() {
        const fieldIds = [];
        for (let parentKey in FIELDS) {
            for (let fieldKey in FIELDS[parentKey]) {
                const fieldId = parentKey + "." + fieldKey;
                fieldIds.push(fieldId);
            }
        }
        setTouchedFields(fieldIds);
    }

    const sendSaveRequest = useCallback((moduleData, allTouched = false) => {
        lastSaveSent.current = moduleData.dirty;
        dispatchModuleData({type: 'is_saving_started'});
        httpClient.put(`${AppConsts.API_PATH}/ambulance-modules/${moduleId}`, buildPutRequestJson(moduleData))
            .then(response => {
                if (response.data.errors) {
                    createSnackbar({
                        action: {
                            text: "Riprova",
                            onClick: () => sendSaveRequest(moduleData)
                        },
                        message: response.data.errors[0].message,
                        autoHideDuration: null
                    });
                    setErrors(response.data.errors);
                    dispatchModuleData({type: 'is_saving_failed'});
                } else {
                    lastSaveDone.current = lastSaveSent.current;
                    const warnings = allTouched
                        ? response.data.warnings
                        : response.data.warnings.filter(w => !w.details?.field || touchedFields.includes(w.details.field));
                    setErrors(warnings);
                    dispatchModuleData({type: 'is_saving_ended', data: response.data.module});
                    if (warnings.length > 0) {
                        createSnackbar({
                            message: generateWarningsMessage(response.data.warnings),
                            type: "error"
                        });
                    }
                }
            }).catch((e) => {
                logError(e);
                dispatchModuleData({type: 'is_saving_failed'});
            });

        if (allTouched) {
            markAllFieldsAsTouched();
        }
    }, [createSnackbar, moduleId, setErrors, touchedFields]);

    const handleGenerate = useCallback(() => {
        sendSaveRequest(moduleData, true);
    }, [moduleData, sendSaveRequest]);

    useEffect(() => {
        lastValidation.current = 0;
        lastSaveSent.current = 0;
        lastSaveDone.current = 0;
        clearTimeout(autosaveTimeout.current);
        resetErrors();
        setTouchedFields([]);

        dispatchModuleData({type: 'is_loading'});
        httpClient.get(`${AppConsts.API_PATH}/ambulance-modules/${moduleId}`).then(response => {
            if (response.data.errors) {
                dispatchModuleData({type: 'load_failed'});
            } else {
                dispatchModuleData({type: 'loaded_successfully', data: response.data});
            }
        }).catch((e) => {
            logError(e);
            dispatchModuleData({type: 'load_failed'});
        });

        httpClient.get(`${AppConsts.API_PATH}/ambulance-modules/scales`).then(response => {
            if (!response.data.errors) {
                setScales(response.data);
            }
        }).catch((e) => {
            logError(e);
        });
    }, [moduleId, resetErrors]);

    useEffect(() => {
        if (moduleData.dirty > lastValidation.current) {
            validateForm();
        }

        if (moduleData.dirty > lastSaveSent.current) {
            clearTimeout(autosaveTimeout.current);
            autosaveTimeout.current = setTimeout(() => {
                if (moduleData.dirty > lastSaveSent.current) {
                    sendSaveRequest(moduleData);
                }
            }, 1000);
        }
    }, [moduleData, sendSaveRequest, validateForm]);

    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = useCallback((newOpen) => () => {
        setDrawerOpen(newOpen);
    }, []);

    const isDirty = moduleData.dirty > lastSaveDone.current;

    if (moduleData.loading === 'failed') {
        return <Page title={"Modulo non trovato"} maxWidth={'lg'}><Box>Modulo non trovato</Box></Page>;
    }
    if (moduleData.loading === 'is_loading' || scales.length === 0) {
        return <CircularProgress/>;
    }
    return (
        <>
            {moduleData.saving && <LinearProgress sx={{position: 'fixed', top: 0, left: 0, right: 0}}/>}
            <LeaveConfirm message={t('common.any_unsaved_data_will_be_lost')} when={isDirty}/>
            <Box sx={{display: 'flex'}}>
                <Drawer
                    sx={{
                        width: 350,
                        flexShrink: 0,
                        display: {xs: 'none', md: 'block'},
                        '& .MuiDrawer-paper': {
                            width: 350,
                            boxSizing: 'border-box',
                            background: 'none',
                            paddingTop: '12px',
                            //paddingTop: HEADER_HEIGHT
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                >
                    <ModuleMenu moduleData={moduleData} />
                </Drawer>
                <Page title={"Modulo Ambulanza"} maxWidth={'lg'} noHeader>
                    <Breadcrumbs aria-label="breadcrumb" sx={{mb: 3, mt: 3}}>
                        <Link underline="hover" color="inherit" to={PathsMap.HOME} component={NavLink}>
                            {AppConsts.NAME}
                        </Link>
                        <Typography color="text.primary">
                            Modulo Ambulanza
                        </Typography>
                    </Breadcrumbs>
                    {errors?.main && errors.main.map((error, errorKey) =>
                        <Alert severity="error" key={errorKey} sx={{mb: 2}}>{error}</Alert>)}
                    <Grid container spacing={3}>
                        <Grid item xs={12}><Divider>Dati</Divider></Grid>
                        <Grid item xs={12}>
                            <ModulePatientData
                                errors={errors}
                                resetErrors={resetErrors}
                                patient={moduleData.patient}
                                handleFieldChange={handleFieldChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <ModuleContactData
                                errors={errors}
                                resetErrors={resetErrors}
                                contact={moduleData.contact}
                                handleFieldChange={handleFieldChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <ModulePatientFirstSymptoms
                                errors={errors}
                                resetErrors={resetErrors}
                                anamnesis={moduleData.anamnesis}
                                handleFieldChange={handleFieldChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <ModulePatientPathologiesDrugs
                                errors={errors}
                                resetErrors={resetErrors}
                                anamnesis={moduleData.anamnesis}
                                handleFieldChange={handleFieldChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <ModulePatientParameters
                                errors={errors}
                                resetErrors={resetErrors}
                                parameters={moduleData.parameters}
                                handleFieldChange={handleFieldChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <ModuleScales
                                scales={scales} />
                        </Grid>
                        <Grid item xs={12}><Divider>Analisi</Divider></Grid>
                        <Grid item xs={12}><ModuleScaleResults/></Grid>
                        <Grid item xs={12}><ModuleAiAssistant handleGenerate={handleGenerate}/></Grid>
                        <Grid item xs={12} sx={{display: {xs: 'block', xl: 'none'}}}><ModuleTimeline/></Grid>
                    </Grid>
                </Page>
                <Drawer
                    sx={{
                        width: 330,
                        display: {xs: 'none', xl: 'block'},
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: 330,
                            boxSizing: 'border-box',
                            background: 'none',
                            //paddingTop: HEADER_HEIGHT
                        },
                    }}
                    variant="permanent"
                    anchor="right"
                >
                    <ModuleTimelineBox/>
                </Drawer>
            </Box>
            <SwipeableEdgeDrawer
                open={drawerOpen}
                toggleDrawer={toggleDrawer}
                sx={{display: {md: 'none'}}}
                preview={
                    <ModuleMenuPreview moduleData={moduleData} />
                }
                content={
                    <ModuleMenu
                        moduleData={moduleData}
                        handleClickCallback={toggleDrawer(false)}
                        hideProgress />
                }
            />
        </>
    );
}

function ModuleMenuStateIcon({state}) {
    if (state === STEP_STATES.COMPLETED) {
        return <Done color="success" />;
    }
    if (state === STEP_STATES.INCOMPLETE) {
        return <InfoOutlined color="error" />;
    }
    return <></>;
}

function ModuleMenuPreview({moduleData}) {
    const {completedSteps, totalSteps, stepsPercentage} = computeStepProgress(moduleData);

    return (
        <Grid container sx={{p: 2}} alignItems="center">
            <Grid item xs={10}>
                <LinearProgress
                    sx={{height: 16, borderRadius: 3}}
                    variant="determinate"
                    color={stepsPercentage <= 40 ? 'error' : stepsPercentage <= 60 ? 'warning' : 'success'}
                    value={stepsPercentage}/>
            </Grid>
            <Grid item xs={2}>
                <Box display="flex" justifyContent="right">
                    <Typography variant="body.2">{completedSteps}/{totalSteps}</Typography>
                </Box>
            </Grid>
        </Grid>
    );
}

function computeStepProgress(moduleData) {
    const completedSteps = Object.values(moduleData.steps).filter(step => step === STEP_STATES.COMPLETED).length;
    const totalSteps = moduleData.steps ? Object.keys(moduleData.steps).length : 0;
    const stepsPercentage = completedSteps / totalSteps * 100;
    return {completedSteps, totalSteps, stepsPercentage};
}

function ModuleMenu({moduleData, hideProgress, handleClickCallback}) {
    const handleClick = (itemId) => {
        document.getElementById(itemId).scrollIntoView({behavior: 'smooth'});
        if (handleClickCallback) {
            handleClickCallback();
        }
    };
    const {completedSteps, totalSteps, stepsPercentage} = computeStepProgress(moduleData);

    return (
        <Box sx={{overflow: 'auto'}}>
            {!hideProgress && <>
                <Box sx={{p: 2}}>
                    <Stack direction="row" sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2
                    }}>
                        <Typography variant="h3">Avanzamento</Typography>
                        <Typography variant="body.2">{completedSteps}/{totalSteps}</Typography>
                    </Stack>
                    <LinearProgress variant="determinate" color={stepsPercentage <= 40 ? 'error' : stepsPercentage <= 60 ? 'warning' : 'success'} value={stepsPercentage}/>
                </Box><Divider/></>}
            <List
                sx={{mb: 1}}
                subheader={<ListSubheader sx={{bgcolor: 'transparent'}}>Dati</ListSubheader>}>
                <ListItem disablePadding onClick={() => handleClick("patient")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <MedicalInformationOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="Dati anagrafici"/>
                        <ModuleMenuStateIcon state={moduleData.steps?.PATIENT} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => handleClick("patient-contact")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <PersonPinCircleOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="Recapito"/>
                        <ModuleMenuStateIcon state={moduleData.steps?.CONTACT} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => handleClick("symptoms")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <HistoryOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="Ora di esordio dei sintomi"/>
                        <ModuleMenuStateIcon state={moduleData.steps?.SYMPTOMS} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => handleClick("pathologies-drugs")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <HealthAndSafetyOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="Patologie e farmaci"/>
                        <ModuleMenuStateIcon state={moduleData.steps?.PATHOLOGIES_DRUGS} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => handleClick("blood-pressure")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <FavoriteBorderOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="Pressione sanguigna"/>
                        <ModuleMenuStateIcon state={moduleData.steps?.BLOOD_PRESSURE} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => handleClick("oxygen-saturation")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <BloodtypeOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="Saturazione ossigeno"/>
                        <ModuleMenuStateIcon state={moduleData.steps?.OXYGEN_SATURATION} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => handleClick("glycemia")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <SmartButtonOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="Glicemia"/>
                        <ModuleMenuStateIcon state={moduleData.steps?.GLYCEMIA} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => handleClick("scales")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <AssignmentOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="Compilazione scale"/>
                        <ModuleMenuStateIcon state={moduleData.steps?.SCALES} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider/>
            <List
                subheader={<ListSubheader sx={{bgcolor: 'transparent'}}>Analisi</ListSubheader>}>
                <ListItem disablePadding onClick={() => handleClick("scales-results")}>
                    <ListItemButton>
                        <ListItemIcon><PollOutlined/></ListItemIcon>
                        <ListItemText primary="Risultati scale"/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => handleClick("ai-assistant")}>
                    <ListItemButton>
                        <ListItemIcon><AutoAwesomeOutlined/></ListItemIcon>
                        <ListItemText primary="Assistente IA"/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => handleClick("timeline")} sx={{display: {xl: 'none'}}}>
                    <ListItemButton>
                        <ListItemIcon><InsightsOutlined/></ListItemIcon>
                        <ListItemText primary="Timeline"/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );
}

function ModulePatientData({patient, handleFieldChange, errors}) {
    return (
        <Paper variant="outlined" id="patient">
            <HeadPaper sx={{p: 3}}>
                <Stack direction="row" alignItems="center" spacing={3}>
                    <Avatar sx={{border: '1px solid ' + green[500], 'bgcolor': 'transparent'}}>
                        <MedicalInformationOutlined sx={{fontSize: 26, color: green[500]}}/>
                    </Avatar>
                    <Typography variant="h2" sx={{mb: 3, mt: 3}}>
                        Dati anagrafici
                    </Typography>
                </Stack>
            </HeadPaper>
            <Box sx={{p: 3}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <TextField
                            fullWidth
                            label="Nome e cognome"
                            defaultValue={patient.name ?? ''}
                            error={!!errors?.['patient.name']}
                            onChange={(e) => {
                                handleFieldChange({
                                    parent: 'patient',
                                    property: 'name',
                                    value: e.target.value,
                                    debounced: e
                                });
                            }}
                            variant="outlined"/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl sx={{width: 1}}>
                            <InputLabel id="genre-label">Genere</InputLabel>
                            <Select
                                labelId="genre-label"
                                fullWidth
                                value={patient.genre ?? ''}
                                error={!!errors?.['patient.genre']}
                                onChange={(e) => {
                                    handleFieldChange({
                                        parent: 'patient',
                                        property: 'genre',
                                        value: e.target.value
                                    });
                                }}
                                label="Genere"
                            >
                                <MenuItem value="MALE">
                                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
                                        <MaleOutlined sx={{fontSize: 22}}/>
                                        <span>Maschio</span>
                                    </Box>
                                </MenuItem>
                                <MenuItem value="FEMALE">
                                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
                                        <FemaleOutlined sx={{fontSize: 24}}/>
                                        <span>Femmina</span>
                                    </Box>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            fullWidth
                            defaultValue={patient.age ?? ''}
                            error={!!errors?.['patient.age']}
                            onChange={(e) => {
                                handleFieldChange({
                                    parent: 'patient',
                                    property: 'age',
                                    value: e.target.value,
                                    debounced: e
                                });
                            }}
                            label="Età"
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="start">anni</InputAdornment>,
                            }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            defaultValue={patient.fiscalCode ?? ''}
                            error={!!errors?.['patient.fiscalCode']}
                            onChange={(e) => {
                                handleFieldChange({
                                    parent: 'patient',
                                    property: 'fiscalCode',
                                    value: e.target.value,
                                    debounced: e
                                });
                            }}
                            label="Codice fiscale"
                            variant="outlined"/>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}

function ModuleContactData({contact, handleFieldChange, errors}) {
    return (
        <Paper variant="outlined" id="patient-contact">
            <HeadPaper sx={{p: 3}}>
                <Stack direction="row" alignItems="center" spacing={3}>
                    <Avatar sx={{border: '1px solid ' + blue[500], 'bgcolor': 'transparent'}}>
                        <PersonPinCircleOutlined sx={{fontSize: 26, color: blue[500]}}/>
                    </Avatar>
                    <Typography variant="h2" sx={{mb: 3, mt: 3}}>
                        Recapito caregiver
                    </Typography>
                </Stack>
            </HeadPaper>
            <Box sx={{p: 3}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="Nome"
                            defaultValue={contact.name ?? ''}
                            error={!!errors?.['contact.name']}
                            onChange={(e) => {
                                handleFieldChange({
                                    parent: 'contact',
                                    property: 'name',
                                    value: e.target.value,
                                    debounced: e
                                });
                            }}
                            variant="outlined"/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl sx={{width: 1}}>
                            <InputLabel id="contact-type-label">È un…</InputLabel>
                            <Select
                                labelId="contact-type-label"
                                fullWidth
                                value={contact.type ?? ''}
                                error={!!errors?.['contact.type']}
                                onChange={(e) => {
                                    handleFieldChange({
                                        parent: 'contact',
                                        property: 'type',
                                        value: e.target.value
                                    });
                                }}
                                label="È un…"
                            >
                                <MenuItem value="RELATIVE">Familiare</MenuItem>
                                <MenuItem value="OTHER">Altro</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="Telefono"
                            variant="outlined"
                            defaultValue={contact.phoneNumber ?? ''}
                            error={!!errors?.['contact.phoneNumber']}
                            onChange={(e) => {
                                handleFieldChange({
                                    parent: 'contact',
                                    property: 'phoneNumber',
                                    value: e.target.value,
                                    debounced: e
                                });
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start"><DialpadOutlined/></InputAdornment>,
                            }}/>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}

function ModulePatientFirstSymptoms({anamnesis, handleFieldChange, errors}) {
    return (
        <Paper variant="outlined" id="symptoms">
            <HeadPaper sx={{p: 3}}>
                <Stack direction="row" alignItems="center" spacing={3}>
                    <Avatar sx={{border: '1px solid ' + deepOrange[500], 'bgcolor': 'transparent'}}>
                        <HistoryOutlined sx={{fontSize: 26, color: deepOrange[500]}}/>
                    </Avatar>
                    <Typography variant="h2" sx={{mb: 3, mt: 3}}>
                        Ora di esordio dei sintomi
                    </Typography>
                </Stack>
            </HeadPaper>
            <Box sx={{p: 3}}>
                <Typography variant="p" color={errors?.['anamnesis.firstSymptomsAtKnown'] ? 'error' : 'text.primary'}>
                    È noto l'orario di esordio dei primi sintomi?
                </Typography>
                <ToggleButtonGroup
                    fullWidth
                    sx={{mt: 2}}
                    value={anamnesis.firstSymptomsAtKnown ?? ''}
                    onChange={(e) => {
                        handleFieldChange({
                            parent: 'anamnesis',
                            property: 'firstSymptomsAtKnown',
                            value: e.target.value === 'true'
                        });
                    }}
                    color="primary"
                    exclusive
                >
                    <ToggleButton value={true}>Sì</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
                <TransitionGroup>
                    {anamnesis.firstSymptomsAtKnown === false && <Collapse>
                        <Box sx={{mt: 3}}>
                            <Typography variant="p"
                                        color={errors?.['anamnesis.firstSymptomsAtType'] ? 'error' : 'text.primary'}>
                                Quale informazione è nota?
                            </Typography>
                            <RadioGroup
                                sx={{mt: 1}}
                                value={anamnesis.firstSymptomsAtType ?? ''}
                                onChange={(e) => {
                                    handleFieldChange({
                                        parent: 'anamnesis',
                                        property: 'firstSymptomsAtType',
                                        value: e.target.value
                                    });
                                }}
                            >
                                <FormControlLabel value="PATIENT_LAST_TIME_SEEN" control={<Radio/>}
                                                  label="Orario in cui il paziente è stato visto in salute l'ultima volta"/>
                                <FormControlLabel value="PATIENT_TIME_WENT_TO_BED" control={<Radio/>}
                                                  label="Se i sintomi sono insorti durante la notte, orario in cui è andato a letto"/>
                            </RadioGroup>
                        </Box>
                    </Collapse>}
                    {anamnesis.firstSymptomsAtKnown !== null &&
                        <DateTimePicker
                            disableFuture
                            sx={{mt: 3}}
                            openTo="hours"
                            viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                            }}
                            error={!!errors?.['anamnesis.firstSymptomsAt']}
                            value={anamnesis.firstSymptomsAt ? new Date(anamnesis.firstSymptomsAt) : null}
                            onChange={(newValue) => {
                                const now = new Date();
                                let finalValue = newValue;
                                if (finalValue > now) {
                                    finalValue.setDate(finalValue.getDate() - 1);
                                }
                                handleFieldChange({
                                    parent: 'anamnesis',
                                    property: 'firstSymptomsAt',
                                    value: finalValue
                                });
                            }}
                            slotProps={{textField: {fullWidth: true}}}
                            label="Orario"
                        />}
                </TransitionGroup>
                <Box sx={{mb: 3, mt: 3}}>
                    <Typography variant="p" color={errors?.['anamnesis.patientFoundAt'] ? 'error' : 'text.primary'}>
                        A che ora è stato trovato il paziente?
                    </Typography>
                </Box>
                <DateTimePicker
                    disableFuture
                    openTo="hours"
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                    }}
                    error={!!errors?.['anamnesis.patientFoundAt']}
                    value={anamnesis.patientFoundAt ? new Date(anamnesis.patientFoundAt) : null}
                    onChange={(newValue) => {
                        handleFieldChange({
                            parent: 'anamnesis',
                            property: 'patientFoundAt',
                            value: newValue
                        });
                    }}
                    slotProps={{textField: {fullWidth: true}}}
                    label="Orario"/>
            </Box>
        </Paper>
    );
}

function ModulePatientPathologiesDrugs({anamnesis, handleFieldChange, errors}) {
    return (
        <Paper variant="outlined" id="pathologies-drugs">
            <HeadPaper sx={{p: 3}}>
                <Stack direction="row" alignItems="center" spacing={3}>
                    <Avatar sx={{border: '1px solid ' + lime[500], 'bgcolor': 'transparent'}}>
                        <HealthAndSafetyOutlined sx={{fontSize: 26, color: lime[500]}}/>
                    </Avatar>
                    <Typography variant="h2" sx={{mb: 3, mt: 3}}>
                        Patologie e farmaci
                    </Typography>
                </Stack>
            </HeadPaper>
            <Box sx={{p: 3}}>
                <Stack direction="row" alignItems="center" spacing={2} sx={{mb: 2}}>
                    <PsychologyAltOutlined/>
                    <Typography variant="p" color={errors?.['anamnesis.isPsychiatric'] ? 'error' : 'text.primary'}>
                        È un paziente psichiatrico, ha assunto ansiolitici nelle ultime 48 ore?
                    </Typography>
                </Stack>
                <ToggleButtonGroup
                    fullWidth
                    value={anamnesis.isPsychiatric ?? ''}
                    onChange={(e) => {
                        handleFieldChange({
                            parent: 'anamnesis',
                            property: 'isPsychiatric',
                            value: e.target.value === 'true'
                        });
                    }}
                    color="primary"
                    exclusive
                >
                    <ToggleButton value={true}>Sì</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
                <Stack direction="row" alignItems="center" spacing={2} sx={{mt: 3, mb: 2}}>
                    <BoltOutlined/>
                    <Typography variant="p" color={errors?.['anamnesis.isEpileptic'] ? 'error' : 'text.primary'}>
                        È un paziente epilettico?
                    </Typography>
                </Stack>
                <ToggleButtonGroup
                    fullWidth
                    value={anamnesis.isEpileptic ?? ''}
                    onChange={(e) => {
                        handleFieldChange({
                            parent: 'anamnesis',
                            property: 'isEpileptic',
                            value: e.target.value === 'true'
                        });
                    }}
                    color="primary"
                    exclusive
                >
                    <ToggleButton value={true}>Sì</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
                <Stack direction="row" alignItems="center" spacing={2} sx={{mt: 3, mb: 2}}>
                    <HeartBrokenOutlined/>
                    <Typography variant="p" color={errors?.['anamnesis.isHeartPatient'] ? 'error' : 'text.primary'}>
                        È un paziente cardiopatico, ha assunto anticoagulanti nelle ultime 48 ore?
                    </Typography>
                </Stack>
                <ToggleButtonGroup
                    fullWidth
                    value={anamnesis.isHeartPatient ?? ''}
                    onChange={(e) => {
                        handleFieldChange({
                            parent: 'anamnesis',
                            property: 'isHeartPatient',
                            value: e.target.value === 'true'
                        });
                    }}
                    color="primary"
                    exclusive
                >
                    <ToggleButton value={true}>Sì</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </Paper>
    );
}

function ModulePatientParameters({parameters, handleFieldChange, errors}) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper variant="outlined" id="blood-pressure">
                    <HeadPaper sx={{p: 3}}>
                        <Stack direction="row" alignItems="center" spacing={3}>
                            <Avatar sx={{border: '1px solid ' + red[500], 'bgcolor': 'transparent'}}>
                                <FavoriteBorderOutlined sx={{fontSize: 26, color: red[500]}}/>
                            </Avatar>
                            <Typography variant="h2" sx={{mb: 3, mt: 3}}>
                                Pressione sanguigna
                            </Typography>
                        </Stack>
                    </HeadPaper>
                    <Box sx={{p: 3}}>
                        <Grid container spacing={2} alignItems="center" columns={20}>
                            <Grid item xs={20} md={9}>
                                <TextField
                                    fullWidth
                                    defaultValue={parameters.minBloodPressure ?? ''}
                                    error={!!errors?.['parameters.minBloodPressure']}
                                    onChange={(e) => {
                                        handleFieldChange({
                                            parent: 'parameters',
                                            property: 'minBloodPressure',
                                            value: e.target.value,
                                            debounced: e
                                        });
                                    }}
                                    helperText="Pressione sistolica (massima)"
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">mmHg</InputAdornment>,
                                    }}/>
                            </Grid>
                            <Grid item md={2} sx={{display: {xs: 'none', md: 'block'}}}>
                                <Box justifyContent="center" display="flex">
                                    <Box>
                                        <Box justifyContent="center"><KeyboardDoubleArrowLeftOutlined/></Box>
                                        <Box sx={{height: 16}}></Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={20} md={9}>
                                <TextField
                                    fullWidth
                                    defaultValue={parameters.maxBloodPressure ?? ''}
                                    error={!!errors?.['parameters.maxBloodPressure']}
                                    onChange={(e) => {
                                        handleFieldChange({
                                            parent: 'parameters',
                                            property: 'maxBloodPressure',
                                            value: e.target.value,
                                            debounced: e
                                        });
                                    }}
                                    helperText="Pressione diastolica (minima)"
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">mmHg</InputAdornment>,
                                    }}/>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper variant="outlined" id="oxygen-saturation">
                    <HeadPaper sx={{p: 3}}>
                        <Stack direction="row" alignItems="center" spacing={3}>
                            <Avatar sx={{border: '1px solid ' + lightBlue[500], 'bgcolor': 'transparent'}}>
                                <BloodtypeOutlined sx={{fontSize: 26, color: lightBlue[500]}}/>
                            </Avatar>
                            <Typography variant="h2" sx={{mb: 3, mt: 3}}>Saturazione ossigeno</Typography>
                        </Stack>
                    </HeadPaper>
                    <Box sx={{p: 3}}>
                        <TextField
                            fullWidth
                            defaultValue={parameters.oxygenSaturation ?? ''}
                            error={!!errors?.['parameters.oxygenSaturation']}
                            onChange={(e) => {
                                handleFieldChange({
                                    parent: 'parameters',
                                    property: 'oxygenSaturation',
                                    value: e.target.value,
                                    debounced: e
                                });
                            }}
                            sx={{textAlign: 'center'}}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>
                            }}/>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper variant="outlined" id="glycemia">
                    <HeadPaper sx={{p: 3}}>
                        <Stack direction="row" alignItems="center" spacing={3}>
                            <Avatar sx={{border: '1px solid ' + indigo[500], 'bgcolor': 'transparent'}}>
                                <SmartButtonOutlined sx={{fontSize: 26, color: indigo[500]}}/>
                            </Avatar>
                            <Typography variant="h2" sx={{mb: 3, mt: 3}}>Glicemia</Typography>
                        </Stack>
                    </HeadPaper>
                    <Box sx={{p: 3}}>
                        <TextField
                            fullWidth
                            defaultValue={parameters.glycemia ?? ''}
                            error={!!errors?.['parameters.glycemia']}
                            onChange={(e) => {
                                handleFieldChange({
                                    parent: 'parameters',
                                    property: 'glycemia',
                                    value: e.target.value,
                                    debounced: e
                                });
                            }}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="start">mg/dl</InputAdornment>,
                            }}/>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
}

function ModuleScales({scales}) {
    const scalesData = scales.map(s => ({
        avatar: s.name.substring(0, 1),
        name: s.name,
        required: s.required,
        formId: s.formId,
        latestSubmission: null, // TODO prendere da oggetto modulo
        color: red, // TODO in base all'ultima sottomissione o scaadenza (red, grey, ecc)
        countdown: null // TODO in base all'ultima sottomissione e s.submissionInterval
    }));

    return (
        <Paper sx={{pb: 1}} variant="outlined" id="scales">
            <HeadPaper sx={{p: 3}}>
                <Stack direction="row" alignItems="center" spacing={3}>
                    <Avatar sx={{border: '1px solid ' + pink[500], 'bgcolor': 'transparent'}}>
                        <AssignmentOutlined sx={{fontSize: 26, color: pink[500]}}/>
                    </Avatar>
                    <Typography variant="h2" sx={{mb: 3, mt: 3}}>Compilazione scale</Typography>
                </Stack>
            </HeadPaper>
            <Grid container spacing={2} sx={{p: 3}}>
                {scalesData.map(scale =>
                    <Grid item xs={12} sm={6} key={scale.name}>
                        <Card variant="outlined">
                            <CardHeader
                                avatar={
                                    <Avatar sx={{color: scale.color[500], border: `1px solid ${scale.color[700]}`, bgcolor: '#fff'}}>
                                        {scale.avatar}
                                    </Avatar>
                                }
                                action={scale.countdown ?
                                    <CountDownTimer
                                        duration={scale.countdown}
                                        colors={["#ff9248", "#a20000"]}
                                        colorValues={[20, 10]}
                                        onComplete={() => {
                                        }}
                                    /> :
                                    <IconButton aria-label="settings" component={NavLink} to={PathGenerator.createFormResponse(scale.formId)}>
                                        <MoreVertOutlined />
                                    </IconButton>
                                }
                                title={
                                    <Typography sx={{fontWeight: scale.required ? 700 : 400}}>
                                        {scale.name}
                                    </Typography>
                                }
                                subheader={
                                    <Typography sx={{color: scale.color[700]}}>
                                        {scale.latestSubmission
                                            ? scale.latestSubmission.toLocaleDateString() : 'Mai compilata'}
                                    </Typography>
                                }
                            />
                        </Card>
                    </Grid>)}
            </Grid>
        </Paper>
    );
}

function ModuleTimeline() {
    return <Paper variant="outlined" id="timeline"><ModuleTimelineBox/></Paper>;
}

function ModuleTimelineBox() {
    return (
        <Box sx={{p: 3}}>
            <Stack direction="row" alignItems="center" spacing={2} sx={{mb: 3}}>
                <Avatar sx={{border: '1px solid ' + teal[500], 'bgcolor': 'transparent'}}>
                    <InsightsOutlined sx={{fontSize: 26, color: teal[500]}}/>
                </Avatar>
                <Typography variant="h2" sx={{mb: 3, mt: 3}}>Timeline</Typography>
            </Stack>
            <Timeline position="alternate">
                <TimelineItem sx={{minHeight: '170px'}}>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0'}}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                    >
                        9:30
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector/>
                        <TimelineDot color="primary">
                        </TimelineDot>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent sx={{pt: '20px', px: 2}}>
                        <Typography variant="h6" component="span">
                            Avvio supporto
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={{minHeight: '170px'}}>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0'}}
                        variant="body2"
                        color="text.secondary"
                    >
                        09:32
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector/>
                        <TimelineDot sx={{bgcolor: Theme.palette.primary.light}}>
                        </TimelineDot>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent sx={{pt: '20px', px: 2}}>
                        <Typography variant="h6" component="span">
                            Dati anagrafici aggiornati
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={{minHeight: '170px'}}>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0'}}
                        variant="body2"
                        color="text.secondary"
                    >
                        09:33
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector/>
                        <TimelineDot sx={{bgcolor: deepOrange[500]}}>
                        </TimelineDot>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent sx={{pt: '20px', px: 2}}>
                        <Typography variant="h6" component="span">
                            Patologie e farmaci aggiornati
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={{minHeight: '170px'}}>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0'}}
                        variant="body2"
                        color="text.secondary"
                    >
                        09:35
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector/>
                        <TimelineDot sx={{bgcolor: pink[500]}}>
                        </TimelineDot>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent sx={{pt: '20px', px: 2}}>
                        <Typography variant="h6" component="span">
                            Scala RACE compilata
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </Box>
    );
}

function ModuleScaleResults() {
    return (
        <Paper variant="outlined" id="scales-results">
            <HeadPaper sx={{p: 3}}>
                <Stack direction="row" alignItems="center" spacing={3}>
                    <Avatar sx={{border: '1px solid ' + teal[500], 'bgcolor': 'transparent'}}>
                        <PollOutlined sx={{fontSize: 26, color: teal[500]}}/>
                    </Avatar>
                    <Typography variant="h2" sx={{mb: 3, mt: 3}}>Risultati scale</Typography>
                </Stack>
            </HeadPaper>
            <Box sx={{p: 3, pt: 0}}>
                <Grid container spacing={4} sx={{mt: 1}}>
                    <Grid item xs={12} md={3}>
                        FAST
                    </Grid>
                    <Grid item xs={8} md={6}>
                        <Stack spacing={3} direction="row" alignItems="center">
                            <Stack spacing={2} direction="row" alignItems="center">
                                <Chip label="3" color="warning" variant="outlined" sx={{fontWeight: 700, fontSize: 16}}/>
                                <Typography color="text.secondary" sx={{fontWeight: 300}}>/</Typography>
                                <Typography color="text.secondary">15</Typography>
                            </Stack>
                            <Typography>
                                Donec et turpis velit. Morbi sed est eget elit rhoncus porttitor vel sit amet tortor.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <SparkLineChart yAxis={{min: 0, max: 7}} data={[2, 2, 3, 4]} height={50}/>
                    </Grid>
                </Grid>
                <Grid container spacing={4} sx={{mt: 1}}>
                    <Grid item xs={12} md={3}>
                        CPSS
                    </Grid>
                    <Grid item xs={8} md={6}>
                        <Stack spacing={3} direction="row" alignItems="center">
                            <Stack spacing={2} direction="row" alignItems="center">
                                <Chip label="6" color="error" variant="outlined" sx={{fontWeight: 700, fontSize: 16}}/>
                                <Typography color="text.secondary" sx={{fontWeight: 300}}>/</Typography>
                                <Typography color="text.secondary">10</Typography>
                            </Stack>
                            <Typography>
                                Donec et turpis velit. Morbi sed est eget elit rhoncus porttitor vel sit amet tortor.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <SparkLineChart yAxis={{min: 0, max: 7}} data={[1, 4, 5, 6]} height={50}/>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}

function ModuleAiAssistant({handleGenerate}) {
    return (
        <Paper variant="outlined" id="ai-assistant">
            <HeadPaper sx={{p: 3}}>
                <Stack direction="row" alignItems="center" spacing={3}>
                    <Avatar sx={{border: '1px solid ' + deepPurple[500], 'bgcolor': 'transparent'}}>
                        <AutoAwesomeOutlined sx={{fontSize: 26, color: deepPurple[500]}}/>
                    </Avatar>
                    <Typography variant="h2" sx={{mb: 3, mt: 3}}>Assistente IA</Typography>
                </Stack>
            </HeadPaper>
            <Box sx={{p: 3}}>
                <Button variant="contained" endIcon={<PlayArrowOutlined />} onClick={handleGenerate}>
                    Genera
                </Button>
                <Grid container spacing={3} sx={{mt: 1}}>
                    <Grid item xs={4}>
                        Attivazione o meno codice Stroke
                    </Grid>
                    <Grid item xs={4}>
                        E' un insulto vascolare ischemico?
                    </Grid>
                    <Grid item xs={4}>
                        Struttura dove indirizzare il paziente
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}